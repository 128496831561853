import axios from "axios";


const httpClient = axios.create(
    { baseURL: process.env.VUE_APP_API_URL }
);

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user.token');
    config.headers.Authorization = 'Bearer '+ token;
    return config;
});

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
     
    function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                window.location = "/login";
            }
        }
        
        return Promise.reject(error);
    }
);

export default httpClient;